// extracted by mini-css-extract-plugin
export var arrowIcon = "index-module--arrowIcon--8JdnJ";
export var closeButton = "index-module--closeButton--kXkUo";
export var container = "index-module--container--2GuoF";
export var continueReadingTitle = "index-module--continueReadingTitle--6l9dz";
export var desktopArticlePreview = "index-module--desktopArticlePreview--qu1Es";
export var desktopLayout = "index-module--desktopLayout--qzdFL";
export var infoContainer = "index-module--infoContainer--+Q2Gv";
export var intro = "index-module--intro--f2L2y";
export var introSmallMessage = "index-module--introSmallMessage--7a6mI";
export var mainContainer = "index-module--mainContainer--pEvab";
export var mobileArticlePreview = "index-module--mobileArticlePreview--EoQRj";
export var mobileDragHandle = "index-module--mobileDragHandle--12Oq8";
export var mobileModalSheet = "index-module--mobileModalSheet--VaFbd";
export var prevArticleButton = "index-module--prevArticleButton--j-vNz";
export var subTitle = "index-module--subTitle--35YW2";
export var title = "index-module--title--Vu6RA";