import type { Data } from "../components/globe";

const API_ROOT_URL = process.env.GATSBY_API_ROOT_URL;

interface UseLocationEmojisAPIValues {
  getLocationEmojis: () => Promise<Data[]>;
  submitUserLocationEmoji: (
    latitude: number,
    longitude: number,
    emoji: string
  ) => void;
}
export default function useLocationEmojisAPI(): UseLocationEmojisAPIValues {
  if (!API_ROOT_URL) {
    return {
      getLocationEmojis: () => Promise.resolve([]),
      submitUserLocationEmoji: () => {},
    };
  }

  async function getLocationEmojis(): Promise<Data[]> {
    try {
      const data = await fetch(`${API_ROOT_URL}/api/get-emoji-location`);
      const json = await data.json();
      const locationEmojis: Data[] = json.data;

      // Try to make sure that the emoji is actually an emoji and not a bad string.
      const EMOJI_REJEX = /\p{Extended_Pictographic}/u;
      const cleanedJSON = locationEmojis.filter(({ emoji }) =>
        EMOJI_REJEX.test(emoji)
      );

      return cleanedJSON;
    } catch (err) {
      return [];
    }
  }

  function submitUserLocationEmoji(
    latitude: number,
    longitude: number,
    emoji: string
  ): void {
    try {
      fetch(`${API_ROOT_URL}/api/add-emoji-location`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          latitude,
          longitude,
          emoji,
        }),
      });
    } catch (err) {
      // log?
    }
  }

  return {
    getLocationEmojis,
    submitUserLocationEmoji,
  };
}
