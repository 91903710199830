import React from "react";
import { GlobeMethods } from "react-globe.gl";
import * as styles from "./index.module.scss";
import cx from "classnames";
import * as THREE from "three";
import type { Data } from "../index";

const ReactGlobeGL = React.lazy(() => import("react-globe.gl"));

const SF_LAT_LNG = {
  latitude: 37.7749,
  longitude: -122.4194,
};

const GLOBE_MATERIAL = new THREE.MeshPhongMaterial({
  color: new THREE.Color("#109ae5"),
});

interface GlobeProps {
  data: Data[];
  width: number;
  height: number;
  shouldPauseRotation?: boolean;
}
const GlobeGL = ({
  data = [],
  width,
  height,
  shouldPauseRotation = false,
}: GlobeProps): JSX.Element => {
  const [isGlobeReady, setIsGlobeReady] = React.useState(false);

  const globeRef = React.useRef<GlobeMethods | undefined>(undefined);

  // Auto-rotate
  React.useEffect(() => {
    if (!globeRef.current || !isGlobeReady) {
      return;
    }
    (globeRef.current.controls() as any).autoRotate = true;
    (globeRef.current.controls() as any).autoRotateSpeed = -0.3;

    // Set initial display center to SF
    const { latitude, longitude } = SF_LAT_LNG;
    globeRef.current.pointOfView({
      lat: latitude,
      lng: longitude,
    });
  }, [isGlobeReady]);

  // Pause/play globe rotation
  React.useEffect((): void => {
    if (!globeRef.current || !isGlobeReady) {
      return;
    }
    const { autoRotate } = globeRef.current.controls() as any;
    if (shouldPauseRotation && autoRotate) {
      (globeRef.current.controls() as any).autoRotate = false;
    } else {
      (globeRef.current.controls() as any).autoRotate = true;
    }
  }, [shouldPauseRotation]);

  const [countries, setCountries] = React.useState({ features: [] });

  // load countries data
  React.useEffect(() => {
    async function fetchCountriesGeoJSON(): Promise<void> {
      const countriesGeoJSON = await import(
        "/static/ne_110m_admin_0_countries.geojson"
      );
      if (!Array.isArray(countriesGeoJSON?.features)) {
        return;
      }
      setCountries(countriesGeoJSON);
    }
    fetchCountriesGeoJSON();
  }, []);

  return (
    <div className={styles.globeContainer}>
      <React.Suspense fallback={<></>}>
        <ReactGlobeGL
          ref={globeRef}
          onGlobeReady={(): void => setIsGlobeReady(true)}
          globeMaterial={GLOBE_MATERIAL}
          backgroundColor="rgba(0,0,0,0)"
          width={width}
          height={height}
          // Render countries if countries exist
          polygonsData={countries.features.filter(
            (data) => (data as any).properties.ISO_A2 !== "AQ"
          )}
          polygonCapColor={() => "#fff"}
          polygonSideColor={() => "#fff"}
          polygonAltitude={0.02}
          // Render emojis
          htmlElementsData={data}
          htmlLat={(data) => (data as Data)?.latitude ?? 0}
          htmlLng={(data) => (data as Data)?.longitude ?? 0}
          htmlElement={(data): HTMLElement => {
            const { emoji, isUser } = data as Data;
            const el = document.createElement("div");
            el.innerHTML = emoji;
            el.className = cx(styles.marker, {
              [styles.user]: isUser,
            });
            return el;
          }}
        />
      </React.Suspense>
    </div>
  );
};

export default GlobeGL;
