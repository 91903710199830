import React from "react";
import * as styles from "./index.module.scss";
import Header from "./header";
import GlobeGL from "./globe-gl";
import useLocationEmojisAPI from "../../hooks/use-location-emojis-api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";

export interface Data {
  latitude: number;
  longitude: number;
  emoji: string;
  isUser?: boolean;
}

interface GlobeContainerProps {
  onClickMobile: () => void;
  isReadyToLoad: boolean;
}
const GlobeContainer = ({
  onClickMobile,
  isReadyToLoad,
}: GlobeContainerProps): JSX.Element => {
  const [data, setData] = React.useState<Data[]>([]);
  const { getLocationEmojis } = useLocationEmojisAPI();

  // Fetch data from API
  React.useEffect((): void => {
    async function fetchData(): Promise<void> {
      const data = await getLocationEmojis();
      setData(data);
    }
    fetchData();
  }, []);

  const [{ width, height }, setContainerSize] = React.useState({
    width: 0,
    height: 0,
  });
  const containerRef = React.useCallback((node: HTMLDivElement) => {
    if (!node) {
      return;
    }
    const { clientWidth, clientHeight } = node;
    if (!clientWidth || !clientHeight) {
      return;
    }
    setContainerSize({
      width: clientWidth,
      height: clientHeight,
    });
  }, []);

  const [isGlobeGLRotationPaused, setIsGlobeGLRotationPaused] =
    React.useState(false);

  return (
    <div
      className={styles.container}
      ref={containerRef}
      onTouchStartCapture={onClickMobile}
    >
      <button
        type="button"
        className={styles.playPauseGlobeRotationButton}
        onClick={(): void => setIsGlobeGLRotationPaused((val) => !val)}
        aria-label={`${
          isGlobeGLRotationPaused ? "Play" : "Pause"
        } globe rotation`}
      >
        <FontAwesomeIcon
          icon={isGlobeGLRotationPaused ? faPlay : faPause}
          size="lg"
        />
      </button>
      <Header
        addUserLocation={(userLocation: Data): void =>
          setData((data) => [
            ...data.filter((item) => !item.isUser),
            userLocation,
          ])
        }
      />
      {Boolean(width) && Boolean(height) && isReadyToLoad && (
        <GlobeGL
          data={data}
          width={width}
          height={height}
          shouldPauseRotation={isGlobeGLRotationPaused}
        />
      )}
    </div>
  );
};

export default GlobeContainer;
