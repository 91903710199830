import React from "react";
import useGetUserLatLng from "../../../hooks/use-get-user-latlng";
import * as styles from "./index.module.scss";
import cx from "classnames";
import helloJSON from "./hello.json";
import type { Data } from "../index";
import useLocationEmojisAPI from "../../../hooks/use-location-emojis-api";

const Picker = React.lazy(() => import("emoji-picker-react"));
const QuickEmojis = React.lazy(() => import("./quick-emojis"));

interface GlobeGreetingHeaderProps {
  addUserLocation: (userLocation: Data) => void;
}
const GlobeGreetingHeader = ({
  addUserLocation,
}: GlobeGreetingHeaderProps): JSX.Element => {
  const [userEmoji, setUserEmoji] = React.useState<string | undefined>(
    undefined
  );

  const { askForUserLatLng, userCoords } = useGetUserLatLng();

  function setUserEmojiOnClick(emoji: string): void {
    setUserEmoji(emoji);
    setShouldShowEmojiSelector(false);
    askForUserLatLng();
  }

  const [{ message: helloMessage, language: helloLanguage }, setHelloMessage] =
    React.useState({
      message: "hello",
      language: "English",
    });

  React.useEffect((): (() => void) => {
    const INTERVAL_MS = 5 * 1000; // 5s

    const id = window.setInterval((): void => {
      const randomIdx = Math.floor(Math.random() * helloJSON.length);
      const { language, hello } = helloJSON[randomIdx];

      if (!hello || !language) {
        return;
      }

      setHelloMessage({
        message: hello,
        language,
      });
    }, INTERVAL_MS);

    return () => window.clearInterval(id);
  }, []);

  const { submitUserLocationEmoji } = useLocationEmojisAPI();

  React.useEffect((): void => {
    if (userEmoji && userCoords) {
      const { latitude, longitude } = userCoords;
      addUserLocation({
        latitude,
        longitude,
        emoji: userEmoji,
        isUser: true,
      });
      submitUserLocationEmoji(latitude, longitude, userEmoji);
    }
  }, [userEmoji, userCoords]);

  const [shouldShowEmojiSelector, setShouldShowEmojiSelector] =
    React.useState(false);

  let greeting: JSX.Element;
  if (!userEmoji) {
    greeting = (
      <>
        Say{" "}
        <div className={styles.hello}>
          <span>
            {helloMessage}! <span className={styles.waveEmoji}>👋</span>
          </span>
          {helloMessage !== "hello" && (
            <span className={styles.helloLanguage}>
              ("hello" in {helloLanguage})
            </span>
          )}
        </div>
      </>
    );
  } else {
    greeting = (
      <>
        <div className={styles.hello}>
          <span>
            {helloMessage} {userEmoji}!
          </span>

          {helloMessage !== "hello" && (
            <span className={styles.helloLanguage}>
              ("hello" in {helloLanguage})
            </span>
          )}
        </div>{" "}
      </>
    );
  }

  // hack: To make sure the randomly generated <QuickEmojis /> are only rendered
  // client-side.
  const [isSSR, setIsSSR] = React.useState(true);

  React.useEffect(() => {
    setIsSSR(false);
  }, []);

  return (
    <header
      className={cx(styles.header, {
        [styles.emojiSelectorIsOpen]: shouldShowEmojiSelector,
      })}
    >
      <h3 className={styles.title}>{greeting}</h3>
      <div className={styles.emojiSelectorContainer}>
        {!userEmoji && (
          <>
            {!isSSR && (
              <React.Suspense fallback={<></>}>
                <QuickEmojis
                  isShowingEmojiSelector={shouldShowEmojiSelector}
                  setUserEmojiOnClick={setUserEmojiOnClick}
                  setShouldShowEmojiSelector={setShouldShowEmojiSelector}
                />
              </React.Suspense>
            )}
            {shouldShowEmojiSelector && (
              <React.Suspense fallback={<></>}>
                <div
                  className={styles.emojiPickerContainer}
                  onKeyDownCapture={(event): void => {
                    if (event.key === "Escape") {
                      setShouldShowEmojiSelector(false);
                    }
                  }}
                >
                  <Picker
                    onEmojiClick={(event, emojiObj) => {
                      setUserEmojiOnClick(emojiObj.emoji);
                      if (typeof (window as any).gtag === "function") {
                        (window as any).gtag("event", "click", {
                          target: "full-emoji-picker",
                          emoji: emojiObj.emoji,
                        });
                      }
                    }}
                    groupVisibility={{
                      recently_used: false,
                    }}
                    searchPlaceholder="Search for an emoji!"
                    pickerStyle={{
                      width: "100%",
                      borderRadius: shouldShowEmojiSelector
                        ? "0 0 12px 12px"
                        : "12px",
                    }}
                  />
                </div>
              </React.Suspense>
            )}
          </>
        )}
      </div>
    </header>
  );
};

export default GlobeGreetingHeader;
