import React from "react";
import { graphql } from "gatsby";
import type { PageProps } from "gatsby";
import Layout from "../layouts";
import * as styles from "./index.module.scss";
import Globe from "../components/globe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowTurnDown } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import useAnimationContext from "../contexts/animation-context";
import {
  getInitialVariant,
  getExitVariant,
  AnimationDirection,
} from "../utilities/animation";
import { BlogListItem } from "../components/blog-list";
import Sheet, { SheetRef } from "react-modal-sheet";

type DataProps = {
  site: {
    siteMetadata: {
      title: string;
    };
  };
};

const Home = ({ data, location }: PageProps<DataProps>) => {
  const infoContainerHeight = React.useRef<number | null>(null);
  const infoContainerOffsetTop = React.useRef<number | null>(null);
  const layoutRef = React.useCallback((node: any) => {
    if (!node) {
      return;
    }
    infoContainerHeight.current = node.clientHeight;
    infoContainerOffsetTop.current = node.offsetTop;
  }, []);

  const siteTitle = data.site.siteMetadata?.title || `Title`;

  const { state, pathname } = location;
  let initialAnimateDir;
  if ((state as any)?.initialAnimateDir) {
    initialAnimateDir = (state as any).initialAnimateDir;
  }

  const [isTransitionDone, setIsTransitionDone] = React.useState(
    !initialAnimateDir
  );

  const { variants, homePageAnimationControls } = useAnimationContext();

  const [isModalSheetOpen, setIsModalSheetOpen] = React.useState(true);

  React.useEffect((): void => {
    homePageAnimationControls.start("visible");
    setIsModalSheetOpen(true);
  }, []);

  const { RTL } = AnimationDirection;

  const [prevArticle, setPrevArticle] = React.useState<any>(
    (state as any)?.blogPostInfo
  );

  const sheetRef = React.useRef<SheetRef>();
  const snapTo = (y: number) => sheetRef.current?.snapTo(y);

  let prevArticleJSX: JSX.Element | undefined;
  if (prevArticle) {
    prevArticleJSX = (
      <div className={styles.prevArticleButton}>
        <h4 className={styles.continueReadingTitle}>
          <span>
            Continue reading{" "}
            <FontAwesomeIcon
              icon={faArrowTurnDown}
              className={styles.arrowIcon}
            />
          </span>
          <button
            type="button"
            className={styles.closeButton}
            onClick={(): void => {
              if (typeof (window as any).gtag === "function") {
                (window as any).gtag("event", "click", {
                  target: "close-prev-article-reminder",
                });
              }
              setPrevArticle(undefined);
              // Clear prev article from location history, otherwise it will
              // persist on refresh.
              const tempState = { ...(state as any) };
              delete tempState.blogPostInfo;
              window.history.replaceState({ ...tempState }, "");
            }}
            aria-label="Close previous article reminder"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </h4>
        <BlogListItem
          post={prevArticle}
          visualSpec="mini"
          shouldRestoreScroll
        />
      </div>
    );
  }

  const layout = (
    <Layout
      ref={layoutRef}
      location={location}
      title={siteTitle}
      seoInfo={{ title: "Hi!" }}
      visualSpec={["no-padding", "v-centered"]}
      headerVisualSpec="home-page"
      className={styles.infoContainer}
      mainClassName={styles.mainContainer}
    >
      <h1 className={styles.title}>Brandon Chong</h1>
      <h2 className={styles.subTitle}>Software Engineer</h2>
      <div className={styles.intro}>
        <p className={styles.introSmallMessage}>
          <em>Say hello to the world by clicking on an emoji!</em>
        </p>
      </div>
      <div className={styles.mobileArticlePreview}>{prevArticleJSX}</div>
    </Layout>
  );

  return (
    <motion.div
      key={pathname}
      animate={homePageAnimationControls}
      initial={getInitialVariant(initialAnimateDir)}
      exit={getExitVariant(RTL)}
      variants={variants}
      className={styles.container}
      onAnimationComplete={(): void => setIsTransitionDone(true)}
    >
      <Sheet
        ref={sheetRef}
        isOpen={isModalSheetOpen}
        initialSnap={0}
        snapPoints={[Boolean(prevArticle) ? 0.85 : 0.65, 0.1]}
        onSnap={(i) => {
          if (i < 0) {
            snapTo(0);
          }
        }}
        onClose={() => {
          if (sheetRef.current?.y && sheetRef.current.y.get() > 0) {
            // Hack: for some reason, the underlying implementation of the
            // get() method will crash when the closing drag velocity is too
            // high. It says that the `this` is undefined when reading
            // `this.current` to get the current `y` position. Because this
            // can only happen when closing the modal sheet, we can assume
            // that the drag direction was top-to-bottom, so we should
            // minimize the modal sheet (i.e. select snap point index 1).
            try {
              const { get: getCurrent, getPrevious } = sheetRef.current.y;
              snapTo(getCurrent() < getPrevious() ? 0 : 1);
            } catch (err) {
              snapTo(1);
            }
          }
        }}
        className={styles.mobileModalSheet}
      >
        {/* hack: Sheet has an unneeded `onViewportBoxUpdate` prop caused by
        framer-motion ^5.0.0.
      // @ts-ignore */}
        <Sheet.Container>
          {/*
      // @ts-ignore */}
          <Sheet.Header disableDrag={false} />
          {/*
      // @ts-ignore */}
          <Sheet.Content>{layout}</Sheet.Content>
        </Sheet.Container>
      </Sheet>
      <div className={styles.desktopLayout}>{layout}</div>
      <Globe
        onClickMobile={(): void => {
          snapTo(1);
        }}
        isReadyToLoad={isTransitionDone}
      />
      <div className={styles.desktopArticlePreview}>{prevArticleJSX}</div>
    </motion.div>
  );
};

export default Home;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
