// extracted by mini-css-extract-plugin
export var arrowIcon = "index-module--arrowIcon--H-Bug";
export var closeButton = "index-module--closeButton--Pt6Xw";
export var container = "index-module--container--zUIlT";
export var continueReadingTitle = "index-module--continueReadingTitle--JXzUS";
export var desktopArticlePreview = "index-module--desktopArticlePreview--g1+fF";
export var desktopLayout = "index-module--desktopLayout--i4ITl";
export var infoContainer = "index-module--infoContainer---3s6J";
export var intro = "index-module--intro--HwSew";
export var introSmallMessage = "index-module--introSmallMessage--g1bRk";
export var mainContainer = "index-module--mainContainer--vDIPs";
export var mobileArticlePreview = "index-module--mobileArticlePreview--Hmk0K";
export var mobileDragHandle = "index-module--mobileDragHandle--CxuLH";
export var mobileModalSheet = "index-module--mobileModalSheet--k88Oj";
export var playPauseGlobeRotationButton = "index-module--playPauseGlobeRotationButton--GRL57";
export var prevArticleButton = "index-module--prevArticleButton--eaou1";
export var subTitle = "index-module--subTitle--7k4jL";
export var title = "index-module--title--hTxdV";