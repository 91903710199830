import React from "react";

interface Coordinate {
  latitude: number;
  longitude: number;
}

interface UseIPToLatLngValues {
  askForUserLatLng: () => void;
  userCoords?: Coordinate;
  error?: GeolocationPositionError;
}
function useGetUserLatLng(): UseIPToLatLngValues {
  const [coords, setCoords] = React.useState<Coordinate | undefined>(undefined);
  const [error, setError] = React.useState<
    GeolocationPositionError | undefined
  >(undefined);

  function successCallback({
    coords: { latitude, longitude },
  }: GeolocationPosition): void {
    setCoords({
      latitude,
      longitude,
    });
  }

  function errorCallback(error: GeolocationPositionError): void {
    setError(error);
  }

  function askForUserLatLng(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    }
  }

  return {
    userCoords: coords,
    askForUserLatLng,
    error,
  };
}

export default useGetUserLatLng;
